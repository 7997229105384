import React from 'react';
import { graphql } from 'gatsby';

import Layout from '/src/components/layout';
import EventList from '/src/components/eventList';
import CalendarLink from '/src/components/calendarLink';
import Head from '/src/components/head';
import FetchEvents from '/src/dao/fetchEvents';
import { H1, H2 } from '/src/components/wrapper/h';

export default ({ data, location }) => {
	const year = 2022;
	const month = 2;
	const imageDirPath = `${year}/${('0' + month).slice(-2)}`;
	const events = FetchEvents(year, month, imageDirPath);

	const siteUrl = data.site.siteMetadata.url;

	return (
		<>
			<Head>
				<title>{`${year}年${month}月お試し引換券 | たらこ`}</title>
				<meta name='description' content={`${year}年${month}月のローソンお試し引換券を紹介`} />
				<link rel='canonical' href={siteUrl + location.pathname}></link>
			</Head>

			<Layout>
				<H1 id='title'>{`お試し引換券 ${year}年${month}月`}</H1>
				<nav>
					<ul>
						<li>
							<a href='#lawson'>月刊ローソン形式での商品紹介</a>
						</li>
						<li>
							<a href='#sheet'>表形式での商品紹介</a>
						</li>
					</ul>
				</nav>
				<hr />
				<article>
					<H2 id='lawson'>月刊ローソン形式での商品紹介</H2>
					<aside>
						<CalendarLink events={events} />
					</aside>
					<EventList events={events} />
				</article>
				<hr />
				<article>
					<H2 id='sheet'>表形式での商品紹介</H2>
					<iframe
						className='embed'
						title='表形式の商品紹介'
						src='https://docs.google.com/spreadsheets/d/e/2PACX-1vQwRVyTTE_t9j24hVhLYGvdvrL_fDf2nT9nt5bsJsKUruMwvNvEjIavDcL1arl0WXqyfYlm8AEPtrvj/pubhtml?gid=1221128535&amp;single=true'
					/>
				</article>
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				url
			}
		}
	}
`;
